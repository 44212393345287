.doc {
  cursor: pointer;
  &:hover {
    background-color: var(--chakra-colors-indigo-200);
  }
}
.doc:hover svg {
  color: var(--chakra-colors-indigo-500);
  background-color: var(--chakra-colors-indigo-200);
}
