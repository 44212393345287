@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.notification-wrapper {
  position: relative;
  display: inline-block;
}

.notification-dot {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 16px;
  width: 16px;
  background-color: #ff4d00;
  border-radius: 50%;
  z-index: 5;
  animation: fadeIn 0.15s;
}
