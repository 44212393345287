@keyframes fadeInUpRefresh {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

#revert-to-default-button {
  transform-origin: center;
  animation: fadeInUpRefresh 0.3s ease-in-out;
}
