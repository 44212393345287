@keyframes hover {
  0% {
    transform: translateY(5px) rotate(315deg);
  }
  50% {
    transform: translateY(0px) rotate(315deg);
  }
  100% {
    transform: translateY(5px) rotate(315deg);
  }
}

@keyframes fadeInRotate {
  from {
    transform: scale(0) rotate(0deg);
    opacity: 0;
  }
  to {
    transform: scale(1) rotate(315deg);
    opacity: 1;
  }
}

#devilboy {
  transform-origin: center;
  animation:
    fadeInRotate 1s ease-out,
    hover 5s ease-in-out infinite;
  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }
  @media (max-width: 1240px) {
    display: none;
  }
}
