@keyframes colorPulse {
  0%,
  100% {
    color: #d5a617;
  }
  50% {
    color: #f9f9f9;
  }
}

.pulse {
  animation: colorPulse 3s infinite ease-in-out;
}
