@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate {
  transform-origin: center;
  animation: spin 1s ease-in-out infinite;
}
