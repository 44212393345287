@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0);
  }
}

.animate-refresh-icon {
  transform-origin: center;
  animation: spin 1s ease-in-out infinite;
  animation-direction: reverse;
}
